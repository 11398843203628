import React from "react"
import PropTypes from "prop-types"
import { throttle } from '../../../lib/helpers'
import styles from "./scrollMenu.module.scss"

class ScrollMenu extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
      layout: [],
      activeIndex: '01',
      SmoothScroll: ''
    }
  }

  detectIfInView = (section = {}, callback = false) => {
    if (!document) { return false }

    const id = `${section.title}-id`
    const el = document.getElementById(id)

    if (!el) { return false }

    const detectPosition = () => {
      const pos = el.getBoundingClientRect()
      if(pos.top < window.innerHeight / 2 && pos.top + pos.height > window.innerHeight / 2){
        callback('true')
      }
    }

    detectPosition()
    window.addEventListener('scroll', throttle(() => detectPosition()), 1500)
  }

  trackAnchors = layout => layout
    .map((section, i) =>
    this.detectIfInView(section, activeIndex => this.setState({ activeIndex: activeIndex === 'true' ? i + 1 : 0 })))

  formatMenu = () => {
    const { pageContext } = this.props
    let layout = []

    if (pageContext === undefined || !pageContext) {
      return false
    }

    const replacePrefix = str => str.replace('WordPressAcf_', '')

    pageContext.acf.modules_page.map((module, index) => {
      if (!module.id) {
        return
      }

      if (module.navigation_title) {
        layout.push({ index: `0${layout.length + 1}`, title: module.navigation_title })
        return layout
      }
    })

    if (layout.length) {
      this.trackAnchors(layout)
    }

    return this.setState({ layout })
  }

  scroll (id, i) {
    var scroll = new this.state.SmoothScroll()
    var anchor = document.getElementById(id)

    if (anchor) {
      scroll.animateScroll(anchor)
    }
  }

  componentDidMount () {
    this.formatMenu()
    this.setState({ SmoothScroll: require('smooth-scroll') })
    setTimeout(() => this.formatMenu(), 200)
    setTimeout(() => this.setState({ animated: true }), 3750)
  }

  render () {
    const { animated, activeIndex, layout } = this.state

    return (
      <nav className={`${styles.scrollMenu} ${animated && `${styles.animated}`}`}>
        {
          layout
            .map(({ index, title }, i) =>
              <li className={activeIndex === i + 1 && styles.active}>
                <button onClick={() => this.scroll(`${title}-id`, i)}>
                  {activeIndex === i + 1 ? title : index }
                </button>
              </li>
            )
        }
      </nav>
    )
  }
}

ScrollMenu.propTypes = {
  menu: PropTypes.array,
}

ScrollMenu.defaultProps = {
  menu: [],
}

export default ScrollMenu
