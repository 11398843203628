import React from 'react'
import api from '../lib/api'
import queryString from 'query-string'
import Layout from '../components/layout'
import acfLayout from '../lib/acfLayout'

// templates
import PageTemplate from '../templates/page'
import PortfolioUnderContent from '../templates/portfolioUnderContent/index'
import DynamicLayoutWithContentHeading from '../templates/dynamicLayoutWithContentHeading/index'
import ComingSoon from '../templates/comingSoon/index'
import Contact from '../templates/contact/index'
import PreviewTemplate from '../templates/preview'

class Container extends React.Component {
  constructor() {
    super()

    this.state = {
      post: {},
    }
  }

  componentDidMount () {
    const { id, wpnonce } = queryString.parse(new URL(this.props.location.href).search)

    api.site.getPreviewData(id, wpnonce).then(post => this.setState({ post }))
  }

  handleTemplate = () => {
    let { post } = this.state

    if (!post || !Object.keys(post).length) {
      return <h1>Loading preview</h1>
    }

    post.title = post.title.rendered
    post.content = post.content.rendered
    post.acf.modules_page = post.acf.modules

    const props = {
      pageContext: post,
      layout: post.acf ? post.acf.modules : [],
      fromREST: true
    }

    switch (post.template) {
      case 'portfolio_under_content.php':
        return <PortfolioUnderContent {...props} />
      case 'dynamic_layout_with_content_heading.php':
        return <DynamicLayoutWithContentHeading {...props} />
      case 'coming_soon.php':
        return <ComingSoon {...props} />
      case 'contact.php':
        return <Contact {...props} />
      default:
        return <PageTemplate {...props} />
    }
  }

  render = () => this.handleTemplate(this.state.post.template)
}

export default Container
