import 'isomorphic-fetch'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import Form from '../../components/organisms/_forms/Contact'

class Contact extends Component {
  constructor () {
    super()

    this.state = {
      error: false,
      submissionError:  null,
      submissionErrorMsg: '',
    }
    this.formRef = null
  }

  submit = ({ name, email, message }) => {
    const title = `Email sent by ${name} at ${email}`
    const body = `
      <p><strong>Name:</strong> ${name}</p>
      <p><strong>Email:</strong> ${email}</p>
      <p><strong>Message:</strong><br /> ${message}</p>
    `

    const url = window.location.href.replace('https://', '').replace('/contact', '').replace(/\/$/, "")
    console.log('url', url);

    let apiBase = 'https://wp.bradyedgar.io'
    switch (url) {
      case 'localhost:8000':
          apiBase = 'https://api.bradyedgar.local'
        break;
      case 'staging.bradyedgar.io':
          apiBase = 'https://wp.bradyedgar.io'
        break;
      case 'www.bradyedgar.io':
          apiBase = 'https://wp.bradyedgar.io'
        break;
    }

    return fetch(`${apiBase}/wp-json/wp/v2/email-archiver/add-entry`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({
        title,
        body
      })
    })
      .then(({ status }) => {
        this.setState({
          submissionError: status !== 200,
          error: status !== 200
        })
    	})
  }

  render () {
    const { error, submissionError, submissionErrorMsg } = this.state

    return (
      <div>
        <Form
          ref={ref => this.formRef = ref}
          onSubmit={this.submit}
          errored={error}
          submissionError={submissionError}
          submissionErrorMsg={submissionErrorMsg}
        />
      </div>
    )
  }
}

export default connect()(Contact)
