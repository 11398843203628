import 'isomorphic-fetch'
// import { API, API_BASE } from './globals'

const api = {
  site: {
    async getPreviewData (id, wpnonce) {
      const response = await fetch(`${process.env.API_PROTOCOL}://${process.env.API_URL}/wp-json/__/v1/post/preview?id=${id}&_wpnonce=${wpnonce}`, { credentials: 'include' })
      const data = await response.json()

      return data
    },
  }
}

export default api
