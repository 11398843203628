import React from 'react'
import Layout from '../components/layout'
import ScrollMenu from '../components/molecules/ScrollMenu'
import acfLayout from '../lib/acfLayout'

export default ({ pageContext, layout, fromREST }) =>
  <Layout>
    <ScrollMenu pageContext={pageContext} />
    { acfLayout({ layout: pageContext.acf.modules_page, fromREST }) }
  </Layout>
