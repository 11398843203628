import React from 'react'
import styles from './contact.module.scss'
import { Field, reduxForm } from 'redux-form'
import Input from '../../../atoms/_forms/_inputs/Input'
import TextArea from '../../../atoms/_forms/_inputs/TextArea'
import RoundArrowButton from '../../../atoms/RoundArrowButton'
import validate from './validate'

const Contact = ({ handleSubmit, submitting, errored, fields = {}, submitFailed, fieldErrors = {}, submissionError, submissionErrorMsg }) =>
  <>
    {
      submissionError === false
      ? <div className={styles.message}>
          <h3>Thank you for your message!</h3>
          <p>Aliquip ex arbitror. Amet nescius hic legam eram qui id noster voluptate eiusmod
          ea nam appellat ab laboris ad ne in distinguantur do an elit e aliqua se.</p>
        </div>
      : <form onSubmit={handleSubmit}>
          {
            !errored
              ? <>
                <div className="fields inline-elements">
                  <Field
                    name="name"
                    type="text"
                    component={Input}
                    label="Name"
                  />
                </div>
                <div className="fields inline-elements">
                  <Field
                    name="email"
                    type="email"
                    component={Input}
                    label="Email"
                  />
                </div>
                <div className="fields inline-elements">
                  <Field
                    name="message"
                    type="textarea"
                    component={TextArea}
                    label="Message"
                  />
                </div>
                <div className="fields inline-elements bottom">
                  {
                    submissionError &&
                    <div className="fields inline-elements error">
                      <p>{submissionErrorMsg}</p>
                    </div>
                  }
                  <RoundArrowButton submit={true} text="Send message" disabled={submitting} />
                </div>
              </>
              : <div className={styles.message}>
                  <h3>Oh no, It looks like something went wrong!</h3>
                  <p>Do don't worry though, we have archived your email attempt and will get back to you as soon as possible. Please feel free to try again later too.</p>
                </div>
          }
        </form>
    }
  </>


export default reduxForm({
  form: 'contact',
  validate,
})(Contact)
