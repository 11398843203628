import React from 'react'
import Layout from '../../components/layout'
import ScrollMonitoringWrapper from '../../components/atoms/ScrollMonitoringWrapper'
import ContentHero from '../../components/molecules/ContentHero'
import ContactDetails from '../../components/organisms/ContactDetails'
import Form from '../../containers/_forms/Contact'
import styles from './contact.module.scss'

class Page extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
    }
  }

  animate = isVisible => {
    setTimeout(isVisible => this.setState({ animated: true }), 1000)
    return isVisible
  }

  render () {
    const { pageContext } = this.props
    const { animated, windowHeight } = this.state

    return (
      <Layout>
        <div className={styles.contactTemplate}>
          <ContentHero title={pageContext.title} content={pageContext.content} />
          <ScrollMonitoringWrapper threshold={[0.15]} isInViewportEvent={this.animate}>
            <div className={`${styles.content} container`}>
              <div className={`${styles.formWrapper} ${animated && styles.animated}`}>
                <div className={styles.caption}>
                  <h2>General Equires</h2>
                  <p>Feel free to shoot me a message and I will be sure to get back to you within 48 hours. I would love to hear what you have to say</p>
                </div>
                <Form />
              </div>
              <div className={styles.contactDetailsWrapper}>
                <ContactDetails animate={animated} />
              </div>
            </div>
          </ScrollMonitoringWrapper>
        </div>
      </Layout>
    )
  }
}

export default Page
