import React from 'react'
import uuid from 'uuid'
import { Link } from 'gatsby'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'
import styles from './contactDetails.module.scss'

class ContactDetails extends React.Component {
  constructor () {
    super()

    this.state = {
      mounted: false,
      windowHeight: 750,
      isVisible: false
    }
  }

  componentDidMount () {
    this.setState({ windowHeight: window.innerHeight })
  }

  render () {
    const { animate } = this.props
    const { mounted, windowHeight, isVisible } = this.state

    return (
      <ScrollMonitoringWrapper offsets={{ top: (-windowHeight / 2) }} isInViewportEvent={isVisible => this.setState({ mounted: true, isVisible })}>
        <div className={`${styles.contactDetails} ${animate && styles.animated}`}>
          <div className={styles.box}>
            <h3>Info</h3>
            <ul>
              <li><a href="#"><i className="fa fa-envelope"></i><span>general@bradyedgar.io</span></a></li>
              <li><a href="#"><i className="fa fa-hand-holding-usd"></i><span>enquiry@bradyedgar.io</span></a></li>
              <li><a href="#"><i className="fa fa-graduation-cap"></i><span>academy@bradyedgar.io</span></a></li>
              <li><a href="#"><i className="fa fa-map-marker-alt"></i><span>Ontario, Canada</span></a></li>
            </ul>
          </div>
          <div className={styles.socialBar}>
            <ul>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCMqRBS56afOHdhdlWwNbhFg"><i className="fab fa-youtube" /></a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/BradyEdgar1"><i className="fab fa-twitter" /></a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://dribbble.com/BradyEdgar"><i className="fab fa-dribbble" /></a></li>
            </ul>
          </div>
        </div>
      </ScrollMonitoringWrapper>
    )
  }
}

export default ContactDetails
